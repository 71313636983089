/* Not "jiffy-api" because it located in the same directory */
import routes from "config/routes";
import { makeFullPath } from "common/utils/request/utils";
import {
  get,
  post,
  destroy,
  patch,
  put,
  persistentPatch,
  nonResponsePost
} from "common/utils/request/methods";

const supportsKeepAlive = typeof Request !== "undefined" && "keepalive" in new Request("");
const supportsSendBeacon = "sendBeacon" in navigator;
const featureUsageUpdate = path => {
  if (supportsKeepAlive) {
    return persistentPatch(path);
  }
  if (supportsSendBeacon) {
    return nonResponsePost(path);
  }
  return patch(path);
};

/* eslint-disable max-lines */
export default {
  cart: {
    delivery: get(makeFullPath("/api/delivery")),
    removeLineItem: destroy(makeFullPath("/cart/:orderNumber/line_items/:lineItemId"))
  },
  canva: {
    designs: get(makeFullPath("/canva/designs")),
    designStatus: get(makeFullPath("/canva/designs/:jobId")),
    selectDesign: post(makeFullPath("/canva/designs/:designId")),
    settings: get(makeFullPath("/canva/settings"))
  },
  productCollections: {
    cart: get(makeFullPath("/threads/cart")),
    createIntent: post(
      makeFullPath(
        "/threads/stripe/create_intent?payment_method_type=card&payment_method_id=:paymentMethodId"
      )
    ),
    // it returns delivery dates for entire cart and it checks available Inventory too
    oosNotify: post(makeFullPath("/threads/product_bundles/:id/favorite_product_bundles")),
    delivery: get(
      makeFullPath(
        "/api/v2/cart/delivery.json?cb=:cb&jiffy_classics_order=true&order_number=:order_number"
      )
    ),
    paymentMethods: get(makeFullPath("/threads/stripe/payment_methods")),
    // it returns estimated delivery date for bundle for given size
    pdpDelivery: post(
      makeFullPath("/api/products/:bundle_id/delivery_estimates/collections_pdp_date")
    ),
    price: post(makeFullPath("/threads/product_bundles/:id/price")),
    updateCartItem: patch(makeFullPath("/threads/cart")),
    productReviews: get(makeFullPath("/threads/product_bundles/:id/reviews")),
    updateAddress: patch(makeFullPath("/api/orders/:order_number/update_threads_checkout_address")),
    updateExpressCheckoutAddress: patch(
      makeFullPath("/api/orders/:order_number/express_checkout_address_change")
    ),
    createExpressCheckoutOrder: post(
      makeFullPath("/api/orders/create_threads_express_checkout_order")
    ),
    updateSetupFutureUsage: patch(
      makeFullPath("/threads/stripe/update_setup_future_usage?payment_intent_id=:paymentIntentId")
    )
  },

  checkoutV2: {
    createIntent: post(
      makeFullPath(
        "/:businessLine/stripe/payment_intents?payment_method_type=card&payment_method_id=:paymentMethodId&reassign=:reassign"
      )
    ),
    paymentMethods: get(makeFullPath("/:businessLine/stripe/payment_methods")),
    updateSetupFutureUsage: patch(
      makeFullPath(
        "/:businessLine/stripe/update_setup_future_usage?payment_intent_id=:paymentIntentId"
      )
    ),
    cart: get(makeFullPath("/:businessLine/cart")),
    delivery: get(
      makeFullPath(
        "/api/v2/cart/delivery.json?cb=:cb&order_number=:order_number&order_type=:order_type"
      )
    )
  },

  print: {
    delivery: get(makeFullPath("/api/v2/cart/delivery.json?cb=:cb&print=true")),
    estimatedDelivery: get(
      makeFullPath("/api/made/current_pdp_date?composition_id=:composition_id")
    )
  },

  vueCart: {
    delivery: get(makeFullPath("/api/v2/cart/delivery.json?cb=:cb")),
    getCartState: get(makeFullPath("/api/v2/cart.json?cb=:cb")),
    removeCouponCode: destroy(makeFullPath("/api/v2/cart/remove_coupon_code.json")),
    removeLineItem: patch(makeFullPath("/api/v2/cart.json")),
    setCouponCode: post(makeFullPath("/api/v2/cart/apply_coupon_code.json")),
    setShipAddress: put(makeFullPath("/api/v2/cart/set_ship_address.json")),
    updateLineItem: patch(makeFullPath("/api/v2/cart.json")),
    setUseStoreCredits: patch(routes.setUseStoreCreditsPath()),
    clearOutOfStockItems: patch(makeFullPath("/api/v2/cart/line_items.json")),
    currentItems: get(makeFullPath("/api/v2/cart/current_items.json")),
    preCut: patch(makeFullPath("/api/v2/cart/pre_cut.json")),
    updateDtfSize: patch(makeFullPath("/api/v2/cart/update_dtf_size.json"))
  },
  blanksOrder: {
    update: put(makeFullPath("/api/cart/blanks_order")),
    removeLineItem: destroy(makeFullPath("/api/cart/blanks_order/line_items/:lineItemId")),
    outOfStock: get(makeFullPath("/api/cart/blanks_order/out_of_stock"))
  },
  product: {
    popular: get(routes.popularProductsPath()),
    currentPrices: get(routes.currentPricesPath()),
    compositionsPath: get(routes.colorPickerCompositionsPath()),
    show_product_grid: get("/products/:productId/show_product_grid"),
    fetchSimilarItemsButtonHtml: get(routes.similarItemsButtonPath()),
    fetchSimilarProductsHtml: get(routes.similarProductsPath()),
    sendSocialMediaConversionApi: post(routes.productSocialMediaConversionApiPath()),
    fetchMatchingProductsHtml: get(routes.matchingProductsPath()),
    fetchDeliveryEstimates: get(routes.pdpDeliveryEstimatesPath()),
    fetchDeliveryEstimatesForCurrentCart: post(routes.pdpDeliveryEstimatesForCurrentCartPath()),
    fetchWithColorMobileGrid: get(routes.pdpMobileGridWithColorPath()),
    fetchCompositionEstimates: get(routes.compositionEstimationDatePath()),
    fetchFlashCompositionEstimates: get(
      makeFullPath("/api/v2/estimates/flash_composition_dates.json")
    ),
    fetchFlashCommonDates: get(makeFullPath("/api/v2/estimates/flash_made_dates.json")),
    fetchDeliverToModal: get(routes.deliverToModalPath()),
    fetchDynamicJavascripts: get(routes.dynamicJavascriptsPath()),
    fetchMoreDecoratorReviews: get(routes.moreDecoratorReviewsPath()),
    fetchProductReviews: get(routes.productReviewsPath()),
    popupAddToCartPath: get(routes.popupAddToCartPath()),
    popupColorPickerPath: get(routes.popupColorPickerPath())
  },
  favorites: {
    getFavoriteItems: get(routes.getFavoriteItemsPath()),
    addFavoriteItem: post(routes.addFavoriteItemPath()),
    removeFavoriteItem: destroy(routes.removeFavoriteItemPath()),
    unsubscribeFavoriteItem: patch(routes.unsubscribeFavoriteItemPath()),
    updateFavoriteNote: patch(routes.updateNoteFavoriteItemPath()),
    subscribe: post(makeFullPath("/api/subscriptions"))
  },
  featureUsage: {
    update: featureUsageUpdate(routes.updateOrderFeatureUsagePath())
  },
  location: {
    search: get(makeFullPath("/api/locations.json")),
    update: post(makeFullPath("/api/locations.json"))
  },
  locations: {
    create: post(makeFullPath("/api/locations.json")),
    update: put(makeFullPath("/api/locations/:id")),
    delete: destroy(makeFullPath("/api/locations/:zipcode")),
    getCurrentLocation: get(makeFullPath("/api/locations/current_location?cb=:cb"))
  },
  proofs: {
    save: post(makeFullPath("/printer/proofs/:proofId")),
    submit: post(makeFullPath("/printer/proofs/:proofId?submit_proof=true")),
    submitNew: post(makeFullPath("/printer/proofs?submit_proof=true")),
    create: post(makeFullPath("/printer/proofs"))
  },
  refunds: {
    create: post(makeFullPath("/refunds"))
  },
  quote: {
    save: post(makeFullPath("/quotes")),
    attachConsumer: post(routes.attachConsumerWithQuotePath())
  },
  review: {
    save: post(makeFullPath("/printers/:token/reviews"))
  },
  conversation: {
    read: post(makeFullPath("/mark_conversation_read/:conversationId"))
  },
  portfolioImages: {
    rotate: post(
      makeFullPath("/yourshop/portfolio_images/:imageId/rotate.json?designer_id=:designerId")
    ),
    upload: post(makeFullPath("/api/portfolio_images"))
  },
  imageConversions: {
    rasterize: post(makeFullPath("/api/image_conversion/rasterize"))
  },
  geolocations: {
    zipcode: get("/print/api/geolocation/zipcode")
  },
  bidsRequests: {
    create: post(makeFullPath("/bids_requests"))
  },
  projects: {
    update: patch(makeFullPath("/api/project")),
    removeDesigner: destroy(makeFullPath("/api/project/remove_designer")),
    checkout: post(makeFullPath("/api/project/checkout")),
    selectDesigner: post(makeFullPath("/api/project/select_designer")),
    updateZipcode: post(makeFullPath("/api/project/update_zipcode"))
  },
  artworks: {
    create: post(makeFullPath("/api/project/artworks")),
    update: put(makeFullPath("/api/project/artworks/:id")),
    destroy: destroy(makeFullPath("/api/project/artworks/:id"))
  },
  printerSignup: {
    emailUser: get(makeFullPath("/printer/signup/email_user"))
  },
  storePreference: {
    post: post("api/store_preference")
  },
  storefrontPortfolio: {
    update: put(makeFullPath("/printer/storefront/portfolio")),
    reorder: put(makeFullPath("/api/portfolio_images/reorder")),
    destroy: destroy(makeFullPath("/api/portfolio_images/:id"))
  },
  printerStorefront: {
    uploadLogo: put(makeFullPath("/printer/logo"))
  },
  printers: {
    index: get(makeFullPath("/printers"))
  },
  printer: {
    requestGreenscreen: post(makeFullPath("/api/greenscreen_request"))
  },
  pricingCalculations: {
    get: get(makeFullPath("/api/printers/:printerId/pricing_calculations")),
    default: get(makeFullPath("/api/pricing_calculations"))
  },
  customerFunnel: {
    interview: {
      update: put(makeFullPath("/project/project-create")),
      deliveryInfo: get(routes.deliveryInfoDekeoFunnelInterviewPath()),
      updateZipcode: put(routes.zipcodeDekeoFunnelInterviewPath())
    },
    artwork: {
      update: put(makeFullPath("/project/artwork"))
    }
  },
  jiffyshirts: {
    survey: {
      store: post(makeFullPath("/surveys/attempts"))
    },
    cart: {
      quantity: get(makeFullPath("/api/cart/quantity", { skipDekeo: true })),
      searchQuotes: get(routes.searchQuotesPath()),
      runQuoteAssignment: get(routes.runQuoteAssignmentPath()),
      rateBotMessage: post(routes.rateBotMessagePath()),
      updateAiMessage: patch(routes.updateAiMessagePath()),
      getCartUpsell: get(makeFullPath("/api/v2/cart/upsell.json?catalog_number=:catalog_number"))
    },
    preference: post(makeFullPath("/account/update_preference")),
    facets: get(makeFullPath("/api/facets", { skipDekeo: true })),
    facetsFilters: get(makeFullPath("/api/facets/filters", { skipDekeo: true })),
    digitalAssetsFacets: get(routes.digitalAssetsFacetsPath()),
    validateAddress: post(makeFullPath("/api/addresses/validate")),
    locations: {
      create: post(makeFullPath("/api/locations"), { skipDekeo: true }),
      update: put(makeFullPath("/api/locations/:id"), { skipDekeo: true })
    },
    orders: {
      uploadShippingList: post(makeFullPath("/api/orders/upload_shipping_list")),
      removeShippingList: destroy(makeFullPath("/api/orders/remove_shipping_list")),
      setShippingSpeed: put(makeFullPath(`/api/orders/:order_number/set_shipping_speed`)),
      updatePaypalSummary: patch(makeFullPath("/api/orders/:order_number/paypal_summary")),
      createPaypalOrder: post(makeFullPath("/api/orders/:order_number/paypal_order")),
      updateApplePayAddressAndRecalculate: post(
        makeFullPath(`/api/orders/:order_number/update_apple_pay_address`)
      ),
      googlePaySummary: patch(makeFullPath(`/api/orders/:order_number/google_pay_summary`)),
      applePayLoad: post(makeFullPath(`/api/orders/:order_number/current_apple_pay_payload`))
    },
    checkout: {
      update: patch(routes.updateCheckoutPath()),
      prepareKlarnaOrder: put(routes.prepareKlarnaOrderPath()),
      generateBraintreeToken: post(routes.generateBraintreeTokenPath()),
      thankYou: get(routes.thankYouPath()),
      submitPayment: patch(makeFullPath("/checkout/update/payment")),
      log: post(routes.loggerCheckoutPath()),
      stripeCreateIntent: post(routes.stripeCreateIntentPath()),
      startSezzleCheckout: post(routes.startSezzleCheckoutPath()),
      crossSellTransfersStoreCredit: get(routes.crossSellTransfersStoreCreditPath())
    },
    carousel: {
      getCarouselImages: get("/api/product_images/:slug?made_dedicated_pdp=:made_dedicated_pdp")
    },
    product: {
      inventory: get(makeFullPath("/api/products/:slug/inv")),
      comments: get(makeFullPath("/api/products/:id/comments")),
      addComment: post(makeFullPath("/api/products/:id/comments")),
      voteComment: post("/api/products/comments/votes"),
      search: get(routes.apiSearchProductsPath()),
      sizesWithColors: get(routes.productSizesColorsPath()),
      adminSizesWithColors: get(routes.adminProductSizesColorsPath())
    },
    savedCart: {
      list: get(makeFullPath("/api/v2/saved_carts.json")),
      current: get(makeFullPath("/api/v2/saved_carts/active.json")),
      create: post(makeFullPath("/api/v2/saved_carts.json")),
      update: put(makeFullPath("/api/v2/saved_carts/:id.json")),
      switch: put(makeFullPath("/api/v2/saved_carts/:id/switch.json")),
      add: post(makeFullPath("/api/cart/add"))
    },
    rebrand: {
      getRedirectUrl: post(makeFullPath("/api/user_tokens"))
    }
  },
  chat: {
    chats: get(routes.chatGptChatsPath()),
    session: get(routes.chatGptSessionPath()),
    delete: destroy(routes.chatGptDeleteChatPath())
  },
  account: {
    auth: {
      signIn: post(makeFullPath("/api/v2/sign_in.json")),
      signUp: post(makeFullPath("/api/v2/sign_up.json"))
    },
    addresses: {
      removeOne: destroy(makeFullPath("/api/orders/:order_number/delete_ship_address")),
      setOne: put(makeFullPath("/api/orders/:order_number/set_ship_address")),
      getAll: get(makeFullPath("/api/account/addresses")),
      create: post(makeFullPath("/account/addresses"))
    },
    taxes: {
      removeCertificate: destroy(makeFullPath("/tax_exempt/remove_complete_form"))
    },
    savedCarts: {
      getAssignmentData: get(routes.savedCartsAssignmentPath()),
      switch: put(routes.switchSavedCartPath()),
      create: post(routes.savedCartsPath()),
      recalculateSavings: get(routes.savedCartsRecalculatePath())
    },
    oauthConnects: {
      getEtsyImportStatus: get(routes.etsyConnectedPath()),
      getShopifyImportStatus: get(routes.shopifyConnectedPath()),
      getSquareImportStatus: get(routes.squareConnectedPath()),
      getWoocommerceImportStatus: get(routes.woocommerceConnectedPath())
    },
    notificationSetting: {
      update: put(routes.updateNotificationSettingPath())
    },
    paymentMethods: {
      create: post("api/account/payment_methods")
    }
  },
  crafterpay: {
    crafters: {
      invoiceEditForm: get(routes.crafterpayEditFormQuotePath()),
      updateOnboarding: patch(routes.crafterpayOnboardingPath()),
      update: patch(routes.crafterpayUpdateCrafterPath()),
      updateLogo: patch(routes.crafterpayUpdateLogoCrafterPath()),
      createQuote: post(routes.crafterpayCreateQuotePath()),
      deactivateQuote: post(routes.crafterpayDeactivateQuotePath()),
      proposeQuote: post(routes.crafterpayProposeQuotePath()),
      createShipAddress: post(routes.crafterpayCreateAddressPath()),
      shareQuoteEmail: post(routes.crafterpayShareQuoteEmailPath()),
      updateQuoteShippingCost: post(routes.crafterpayUpdateQuoteShippingCostPath()),
      saveDeposit: patch(routes.crafterpaySaveQuoteDepositPath()),
      quoteStatus: get(routes.crafterpayQuotePath()),
      saveQuote: patch(routes.crafterpayQuotePath()),
      updateQuoteTotal: patch(routes.crafterpayUpdateQuoteTotalPath()),
      updateQuoteCrafterMessage: patch(routes.crafterpayUpdateQuoteCrafterMessagePath()),
      updateQuoteCompletionDate: patch(routes.crafterpayUpdateQuoteCompletionDatePath()),
      updateQuoteDeliveryDate: patch(routes.crafterpayUpdateQuoteDeliveryDatePath()),
      updateQuoteDeliveryMethod: patch(routes.crafterpayUpdateQuoteDeliveryMethodPath()),
      updateQuoteInvoiceDetails: patch(routes.crafterpayUpdateQuoteInvoiceDetailsPath()),
      updateQuoteDueDate: patch(routes.crafterpayUpdateQuoteDueDatePath()),
      updateQuoteBalanceDueDate: patch(routes.crafterpayUpdateQuoteBalanceDueDatePath()),
      createLineItem: post(routes.crafterpayLineItemsPath()),
      updateLineItem: patch(routes.crafterpayLineItemPath()),
      destroyLineItem: destroy(routes.crafterpayLineItemPath()),
      addLineItemImage: post(routes.crafterpayLineItemAddImagePath()),
      removeLineItemImage: destroy(routes.crafterpayLineItemRemoveImagePath()),
      invoices: get(routes.invoicesListPath()),
      calculateBalanceAndDeposit: post(routes.crafterpayCalculateBalanceAndDeposit()),
      previewEarnings: get(routes.crafterpayPreviewEarnings()),
      completeOrder: post(routes.crafterpayCompleteOrderPath())
    },
    consumers: {
      checkInvoiceRefreshRequired: get(routes.crafterpayCheckInvoiceRefreshRequiredPath())
    }
  },
  consumers: {
    list: get(routes.consumersPath()),
    create: post(routes.consumersPath()),
    update: patch(routes.consumerPath()),
    delete: destroy(routes.consumerPath()),
    attachToOrder: post(routes.attachConsumerToOrderPath()),
    detachFromOrder: post(routes.detachConsumerFromOrderPath()),
    updateAddress: patch(routes.consumerAddressPath()),
    createAddress: post(routes.consumerAddressPath())
  },
  merchantListings: {
    unmappedLineItems: get(routes.unmappedLineItemsPath()),
    sync: patch(routes.syncMerchantListingsPath()),
    unassigned: get(routes.unassignedMerchantListingsPath()),
    undoAssign: patch(routes.undoAssignMerchantListingsPath()),
    update: patch(routes.merchantListingsPath()),
    variants: {
      list: get(routes.merchantVariantsPath()),
      update: patch(routes.updateMerchantVariantPath()),
      updateBulk: patch(routes.bulkUpdateMerchantVariantPath()),
      // saveKeyword: patch(routes.saveEtsyKeywordPath()),
      adminList: get(routes.adminMerchantVariantsPath()),
      adminUpdate: patch(routes.adminUpdateMerchantVariantPath()),
      adminUpdateBulk: patch(routes.adminBulkUpdateMerchantVariantPath())
    }
  },
  websiteNotifications: {
    index: get(routes.websiteNotificationsList()),
    update: patch(routes.updateWebsiteNotification())
  },
  menu: {
    fetchMobileMenu: get(routes.mobileMenuItemsPath()),
    fetchMobileBanner: get(routes.mobileMenuBannerPath())
  },
  user: {
    current: post(makeFullPath("/api/users/current"))
  },
  dtf: {
    getArtwork: get(makeFullPath("/api/users/dtf_artworks/:id")),
    getArtworksFromOrder: get(
      makeFullPath("/api/users/dtf_artworks/dtf_artworks_from_order/:order_number")
    ),
    /** @type {(formData: FormData, options: unknown) => Promise<import('./models').dtf.uploadArtwork.Response>} */
    uploadArtwork: post(makeFullPath("/api/users/dtf_artworks")),
    stickerCutlines: get(makeFullPath("/api/users/dtf_artworks/:id/sticker_cutlines")),
    updateArtworkStyle: post(makeFullPath("/api/users/dtf_artworks/:id/update_artwork_style")),
    isNewDtfCustomer: get(makeFullPath("/api/users/dtf_artworks/is_new_dtf_customer")),
    pdpCtaSalePrice: post(makeFullPath("/api/products/dtf_pdp_cta_sale_price")),
    dtfPricing: post(makeFullPath("/api/products/:product/dtf_pricing")),
    addToCart: post(makeFullPath("/api/cart/dtf_add")),
    firstTimePromoModal: get(makeFullPath("/api/dtf/first_time_promo_modal")),
    firstStickerPromoModal: get(makeFullPath("/api/dtf/first_sticker_promo_modal")),
    getInitConfig: get(makeFullPath("/api/dtf/init_config")),
    getArtworkHistory: get(makeFullPath("/api/users/dtf_artworks/purchased")),
    getArtworkHistories: get(makeFullPath("/api/users/dtf_artworks/list")),
    getSpangleVariation: post(makeFullPath("/api/users/dtf_artworks/:id/spangle")),
    updateSpangleColor: post(makeFullPath("/api/users/dtf_artworks/change_color")),
    getStickersQuantities: post(makeFullPath("/api/stickers/prices/quantities")),
    getStickersPrices: post(makeFullPath("/api/stickers/prices")),
    generateCmykImages: post(makeFullPath("/api/users/dtf_artworks/:id/generate_cmyk_images")),
    generateSpangleImage: post(makeFullPath("/api/users/dtf_artworks/:id/generate_spangle_image")),
    cancelArtworkProcess: post(makeFullPath("/api/users/dtf_artworks/:id/cancel_artwork_process")),
    generateLettersPreview: post(makeFullPath("/api/dtf/generate_letters_preview")),
    generateLettersSizes: post(makeFullPath("/api/dtf/generate_letters_sizes")),
    createMailChimpEntry: post(makeFullPath("/api/dtf/create_mailchimp_entry")),
    segmentImage: post(makeFullPath("/api/users/dtf_artworks/segment_image")),
    toggleSegmentVersion: post(makeFullPath("/api/users/dtf_artworks/toggle_segment_version")),
    updateArtworkRedrawData: post(
      makeFullPath("/api/users/dtf_artworks/:id/update_artwork_redraw_data")
    ),
    addToCartGangSheet: post(makeFullPath("/api/users/dtf_artworks/add_to_cart_gang_sheet")),
    fetchBulkPrice: get(makeFullPath("/api/users/dtf_artworks/fetch_bulk_price"))
  },
  digitalAssets: {
    getUploadFileIds: get(makeFullPath("/api/designs/assets/:slug/upload_file_ids")),
    savedAssets: get(makeFullPath("/api/designs/assets/saved_assets")),
    addToCart: post(makeFullPath("/api/cart/digital_add")),
    setToMyLibrary: post(makeFullPath("/api/designs/assets/:slug/save")),
    reorderPreviews: post(makeFullPath(routes.adminReorderAssetsPath()))
  },
  borneo: {
    uploadAsset: post(routes.borneoAssetUploadPath())
  },
  brands: {
    getProductCompositions: get(makeFullPath("/brands/:brand_name/cms/compositions")),
    getPagesOrder: get(makeFullPath("/brands/:brand_name/cms/pages_order")),
    updatePagesOrder: patch(makeFullPath("/brands/:brand_name/cms/pages_order")),
    followPage: post(makeFullPath("/brand_followers")),
    searchProducts: get(makeFullPath("/brands/:brand_name/cms/search_product")),
    unfollowPage: destroy(makeFullPath("/brand_followers/:brand_id")),
    updateCmsPage: put(makeFullPath("/brands/:brand_name/cms")),
    updateCmsPageSlug: put(makeFullPath("/brands/:brand_name/cms/pages/:slug")),
    unpublishCmsPage: destroy(makeFullPath("/brands/:brand_name/cms/publish_requests")),
    updateProductSection: put(makeFullPath("/brands/:brand_name/cms/pages/:slug/product_section"))
  },
  ordersClaims: {
    uploadImages: post(routes.claimsUploadImagesPath()),
    cancelComplete: post(routes.claimsCancelCompletePath()),
    reorderComplete: post(routes.claimsReorderCompletePath())
  },
  printOnDemand: {
    currentPodOrder: get(makeFullPath("/api/made/current_order")),
    updatePodOrder: put(makeFullPath("/api/made/current_order")),
    getProducts: get(makeFullPath("/api/made/products")),
    getProduct: get(makeFullPath("/api/made/products/:id")),
    getPrintableAreas: get(makeFullPath("/api/made/printable_areas")),
    getLivestylePreviewUrl: get(makeFullPath("/api/made/placements/:placement_id/preview_url")),
    getCompositionPrices: get(makeFullPath("/api/made/compositions/:id/prices")),
    getFlashStock: get(makeFullPath("/api/pdp/:slug/flash_inventory")),
    getFlashCompositions: get(makeFullPath("/api/pdp/:slug/flash_compositions")),
    getMadePreset: get(makeFullPath("/api/made/presets")),
    createMadePreset: post(makeFullPath("/api/made/presets/:side")),
    updateMadePreset: patch(makeFullPath("/api/made/presets/:side")),
    removeMadePreset: destroy(makeFullPath("/api/made/presets/:side"))
  },
  bulkOrder: {
    getSuppliers: get(makeFullPath(routes.getSuppliersBulkOrderPath()))
  }
};
