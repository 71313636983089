/* eslint-disable max-lines */

// eslint-disable-next-line import/prefer-default-export
import { capitalize } from "common/utils/format/word";
import { BUSINESS_LINE } from "common/utils/app-registry/contants";
import getVariantInfo from "common/jiffy/get-variant";
import { getPageName } from "common/utils/pages";
import sendServerSideConversion from "common/utils/conversion-tracking";
import logger from "../logger";
import retrySubmitRudderstackEvents from "./retry-rudderstack";
import trackRudderstackEvent from "./track-rudderstack-event";

export const track = (eventType, rest) => {
  if (window.dataLayer && window.rudderanalytics) {
    trackRudderstackEvent(eventType, rest);
  } else {
    retrySubmitRudderstackEvents(eventType, rest);
  }

  logger.log(`Rudderstack Track ${eventType}: ${JSON.stringify(rest)}`);
};

const checkoutSteps = ["order_checkout", "review_your_order", "checkout_complete"];
const $getOrderNumberContainer = () => document.querySelector(".js-order-number-container");
const $getCheckoutContainer = () => document.getElementById("checkout");
const $getActiveShipmentRadioButtons = () =>
  document.querySelectorAll(
    '.mcu-order-shipping-speed-selector__radio-wrapper.active input[type="radio"]'
  );
const $getCartSummarySubtotal = () => document.querySelector(".js-cart-summary-subtotal");

const checkoutInitiatedAdditionalData = () => {
  const $orderNumberContainer = $getOrderNumberContainer();
  const $cartSummarySubtotal = $getCartSummarySubtotal();
  const orderNumber = $orderNumberContainer?.dataset?.orderNumber;
  const cartTotal = $cartSummarySubtotal?.dataset?.cartTotal;
  const currencyCode = $cartSummarySubtotal?.dataset?.currencyCode;

  // we exclude this for rudderstack, but can use it for other platforms
  return {
    other_platform_data: {
      tiktok: {
        event_id: `CI:${orderNumber}:${Date.now()}`,
        event_value: cartTotal,
        currency: currencyCode
      }
    }
  };
};

export const trackCheckoutStepViewed = stepName => {
  const stepNumber = checkoutSteps.indexOf(stepName) + 1;
  const $checkoutContainer = $getCheckoutContainer();
  const $orderNumberContainer = $getOrderNumberContainer();
  let shippingMethods = [];

  if (stepNumber === 0) return;

  if ($checkoutContainer) {
    shippingMethods = JSON.parse($checkoutContainer.dataset.checkoutData)?.shipping_methods;
  } else {
    shippingMethods = Array.from($getActiveShipmentRadioButtons()).map(radio => radio.value);
  }

  track("Checkout Step Viewed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    is_app: Boolean(window.MOBILE_APP),
    step_name: stepName,
    step_number: stepNumber,
    shipping_methods: shippingMethods,
    order_number: $orderNumberContainer?.dataset?.orderNumber,
    cart_name: $orderNumberContainer?.dataset?.cartName
  });
};

export const trackCheckoutStepCompleted = (stepName, clickTarget = "") => {
  const stepNumber = checkoutSteps.indexOf(stepName) + 1;
  const $checkoutContainer = $getCheckoutContainer();
  const $orderNumberContainer = $getOrderNumberContainer();
  let shippingMethods = [];

  if (stepNumber === 0) return;

  if ($checkoutContainer) {
    shippingMethods = JSON.parse($checkoutContainer.dataset.checkoutData)?.shipping_methods;
  } else {
    shippingMethods = Array.from($getActiveShipmentRadioButtons()).map(radio => radio.value);
  }

  const additionalData = stepName === "order_checkout" ? checkoutInitiatedAdditionalData() : {};

  track("Checkout Step Completed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    is_app: Boolean(window.MOBILE_APP),
    step_name: stepName,
    step_number: stepNumber,
    shipping_methods: shippingMethods,
    click_target: clickTarget,
    order_number: $orderNumberContainer?.dataset?.orderNumber,
    cart_name: $orderNumberContainer?.dataset?.cartName,
    delivery_date_changed: null,
    expected_delivery_date: null,
    ...additionalData
  });

  if (stepName === "order_checkout") {
    sendServerSideConversion("InitiateCheckout", additionalData.other_platform_data.tiktok);
  }
};

export const trackProductViewed = async eventData => {
  const $orderNumberContainer = $getOrderNumberContainer();

  track("Product Viewed", {
    store_id: $orderNumberContainer?.dataset?.storeId,
    ...eventData
  });
};

const additionalPayload = rawPayload => {
  let payload = {
    is_app: Boolean(window.MOBILE_APP),
    business_line:
      rawPayload && rawPayload.business_line ? rawPayload.business_line : BUSINESS_LINE.DTF,
    zoom_percentage: Math.round((window.outerWidth / window.innerWidth) * 100)
  };

  const currentStoreCode = document.documentElement.lang === "en-CA" ? "ca" : "us";
  if (currentStoreCode) {
    payload = { ...payload, store_code: currentStoreCode };
  }

  const catalogNumber =
    document.querySelector(".js-dtf-product")?.dataset?.catalogNumber ||
    document.querySelector(".js-quantity-form-input")?.dataset?.catalogNumber;
  if (catalogNumber) {
    payload = { ...payload, catalog_number: catalogNumber };
  }

  if (rawPayload.artworks_count !== undefined) {
    payload = { ...payload, multi_artwork: rawPayload.artworks_count > 1 };
  }

  return payload;
};

export const trackRudderstackImageUploadStarted = rawPayload => {
  const payload = {
    ...rawPayload,
    ...additionalPayload(rawPayload)
  };

  track("Image Upload Started", payload);
};

export const trackRudderstackImageUploadCompleted = rawPayload => {
  const { file, artwork, ...cleanPayload } = rawPayload;
  const uploadedFile = file || artwork?.file;

  const payload = {
    ...cleanPayload,
    file_type: uploadedFile.type,
    file_size: uploadedFile.size,
    file_name: uploadedFile.name,
    ...additionalPayload(rawPayload)
  };

  track("Image Upload Completed", payload);
};

export const trackRudderstackImageProcessingCompleted = rawPayload => {
  const payload = {
    ...rawPayload,
    ...additionalPayload(rawPayload)
  };

  track("Image Processing Completed", payload);
};

export const trackRudderstackImageUploadError = rawPayload => {
  const payload = {
    ...rawPayload,
    ...additionalPayload(rawPayload)
  };

  track("Image Upload Error", payload);
};

export const trackRudderstackImageUploadRetry = rawPayload => {
  const payload = {
    ...rawPayload,
    ...additionalPayload(rawPayload)
  };

  track("Image Upload Retry", payload);
};

export const trackRudderstackImageRemoved = rawPayload => {
  const payload = {
    ...rawPayload,
    ...additionalPayload(rawPayload)
  };

  track("Image Removed", payload);
};

export const trackRudderstackImageEdited = rawPayload => {
  const { artwork, ...cleanPayload } = rawPayload;
  const selection = artwork.enhancementTier || "PrintPerfect";

  const payload = {
    ...cleanPayload,
    file_type: artwork.file?.type || artwork.filetype,
    file_size: artwork.file?.size || artwork.filesize,
    file_name: artwork.file?.name || artwork.filename,
    print_perfect_selection:
      selection === "full_enhancements" ? "PrintPerfect" : capitalize(selection),
    print_perfect_price: artwork.pricing?.enhancementTierPrice || "0.0",
    remove_background_toggle: artwork.removeBackground,
    remove_background_toggle_switch: rawPayload.remove_background_toggle_switch || "no_change",
    dpi: artwork.metadata?.dpi,
    ...additionalPayload(rawPayload)
  };

  if (payload.edit_type === "Print as uploaded") {
    payload.print_perfect_selection = null;
    payload.print_perfect_price = "0.0";
    payload.remove_background_toggle = null;
    payload.remove_background_toggle_switch = null;
  } else if (payload.edit_type === "Jiffy Print Perfect") {
    if (payload.print_perfect_selection === "Custom") {
      payload.remove_background_toggle = null;
      payload.remove_background_toggle_switch = null;
    }
  }

  const payloadWithPrice = {
    old_price: payload.price || artwork.price,
    new_price: payload.price || artwork.price,
    ...payload
  };

  track("Image Edited", payloadWithPrice);
};

export const trackRudderstackBlanksProductPriceChanged = async rawPayload => {
  const { variantId, ...restPayload } = rawPayload;
  const variantInfo = await getVariantInfo(variantId);

  // eslint-disable-next-line camelcase
  const { is_app, store_code } = additionalPayload(rawPayload);

  const payload = {
    ...restPayload,
    // eslint-disable-next-line camelcase
    is_app,
    // eslint-disable-next-line camelcase
    store_code,
    variant_id: variantId,
    brand: variantInfo.product.brand,
    category: variantInfo.product.adwords_category, // Blanks or Transfers or Stickers
    currency: variantInfo.currency,
    favorited: variantInfo.favorite,
    url: window.location.href,
    image_url: variantInfo.product.image,
    position: variantInfo.position,
    name: variantInfo.product.name,
    size: variantInfo.size,
    sku: variantInfo.sku,
    variant: variantInfo.color
  };

  track("Product Price Edited", payload);
};

export const extractTextFromLink = link => {
  let linkText = "";

  if (link.children.length > 0) {
    linkText = Array.from(link.children)
      .map($el => $el?.textContent?.trim() || "")
      .join(" ")
      .trim();
  }

  if (linkText.length === 0) linkText = link.textContent.trim();

  return linkText;
};

export const trackRudderstackChatInteraction = rawPayload => {
  // eslint-disable-next-line camelcase
  const { is_app, store_code } = additionalPayload(rawPayload);

  const payload = {
    ...rawPayload,
    // eslint-disable-next-line camelcase
    store_code,
    // eslint-disable-next-line camelcase
    is_app,
    // eslint-disable-next-line max-lines
    location: getPageName() || window.location.pathname,
    platform: window.MOBILE_APP_PLATFORM || "web",
    url: window.location.href
  };

  track("Chat Interaction", payload);
};

export const trackRudderstackImagePlacementSelection = (action, trackProperties) => {
  // eslint-disable-next-line camelcase
  const { catalog_number } = additionalPayload(trackProperties);

  // eslint-disable-next-line camelcase
  track(`Image Placement ${action}`, { ...trackProperties, catalog_number });
};

export const trackRudderstackImagePlacementQuantity = trackProperties => {
  const catalogNumber = additionalPayload(trackProperties).catalog_number;

  track("Image Placement Quantity Adjusted", { ...trackProperties, catalog_number: catalogNumber });
};

export const trackRudderstackImagePlacementColor = trackProperties => {
  const catalogNumber = additionalPayload(trackProperties).catalog_number;

  track("Image Placement Color Selected", { ...trackProperties, catalog_number: catalogNumber });
};
